nav {
  background-color: white;
  height: 64px;
  position: fixed;
  width: 100%;
  z-index: 99;
  text-transform: uppercase;

  ul {
    max-width: 1200px;
    margin: 0 auto;
    list-style-type: none;
    padding: 0 20px;
    position: relative;
    height: 100%;

    display: grid;
    grid-template-areas: "logo . about offers useCase contact .";
    grid-template-columns: max-content auto repeat(5, max-content) auto;

    img {
      height: 40px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    .link {
      float: left;
      color: var(--primaryColor);
      font-weight: 600;
      padding: 22px 25px;
      font-size: 1.4rem;
    }

    .link:hover {
      cursor: pointer;
    }

    #dropdown {
      grid-area: offers;
      position: relative;
      transition-duration: 0.5s;

      #dropbtn {
        color: var(--primaryColor);
        font-weight: bold;
        font-size: 1.3rem;
        background: none;
        border: none;
        cursor: pointer;
      }

      #dropdown-content {
        display: none;
        position: absolute;
        background-color: var(--primaryColor);
        width: max-content;
        top: 64px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;

        a {
          color: white;
          font-weight: 500;
          padding: 1px 16px;
          text-decoration: none;
          font-size: 1.4rem;
          display: block;
        }
        a:hover {
          background-color: var(--darkerPrimary);
        }
      }
    }

    #dropdown2 {
      grid-area: useCase;
      position: relative;
      transition-duration: 0.5s;

      #dropbtn2 {
        color: var(--primaryColor);
        font-weight: bold;
        font-size: 1.3rem;
        background: none;
        border: none;
        cursor: pointer;
      }

      #dropdown-content2 {
        display: none;
        position: absolute;
        background-color: var(--primaryColor);
        width: max-content;
        top: 64px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;

        a {
          color: white;
          font-weight: 500;
          padding: 1px 16px;
          text-decoration: none;
          font-size: 1.4rem;
          display: block;
        }
        a:hover {
          background-color: var(--darkerPrimary);
        }
      }
    }

    #dropdown:hover {
      #dropdown-content {
        display: block;
      }
    }

    #dropdown2:hover {
      #dropdown-content2 {
        display: block;
      }
    }

    #nav-about {
      grid-area: about;
    }

    #nav-contact {
      grid-area: contact;
    }

    #nav-partners {
      grid-area: partners;
    }

    #nav-support {
      grid-area: support;
    }

    .lang-container {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      margin-right: 1.4em;

      .lang-btn {
        border: none;
        background: none;
        color: #333;
        height: 30px;
        width: 30px;
        position: relative;
        margin-bottom: 5px;
        font-size: 1.2rem;
        font-weight: bold;
      }
      .lang-btn:focus {
        outline: 0;
      }
      .lang-btn:hover {
        border-bottom: 1px solid black;
      }
      span {
        height: 20px;
        width: 0.2px;
        font-size: 1.2rem;
        opacity: 0.5;
      }
    }

    a {
      text-decoration: none;
    }
  }

  .menuClosed {
    display: none;
  }
}

@media only screen and (max-width: 1000px) {
  nav {
    ul {
      li {
        display: none;
      }
      #dropdown {
        display: none;
      }
      #dropdown2{
        display:none;
      }
      .lang-container {
        display: none;
      }
      #nav-about {
        display: none;
      }
      #nav-contact {
        display: none;
      }
    }

    .menu {
      padding: 0.8em 1em;
      position: absolute;
      right: 10px;
      top: 6px;

      .bar1,
      .bar2,
      .bar3 {
        width: 35px;
        height: 5px;
        background-color: var(--primaryColor);
        margin: 6px 0;
        transition: 0.4s;
      }
    }
    .closed {
      display: inline-block;
      cursor: pointer;
    }
    .open {
      .bar1 {
        -webkit-transform: rotate(-45deg) translate(-9px, 6px);
        transform: rotate(-45deg) translate(-9px, 6px);
      }
      .bar2 {
        opacity: 0;
      }
      .bar3 {
        -webkit-transform: rotate(45deg) translate(-8px, -8px);
        transform: rotate(45deg) translate(-8px, -8px);
      }
    }

    .menuOpen {
      position: absolute;
      text-align: center;
      padding: 1em 0;
      left: 0px;
      top: 64px;
      width: 100%;
      height: auto;
      overflow: scroll;
      background-color: var(--primaryColor);
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);

      a {
        color: white;
        font-weight: 500;
        font-size: 2rem;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
      }
      a:hover {
        background-color: var(--darkerPrimary);
      }

      .lang-container {
        margin: 10px 0;
        position: relative;
        .lang-btn {
          border: none;
          background: none;
          color: white;
          height: 30px;
          width: 30px;
        }

        span {
          height: 20px;
          width: 0.2px;
          font-size: 1.2rem;
          opacity: 0.5;
          color: white;
          position: absolute;
          top: 7px;
        }
      }
    }
  }
}
