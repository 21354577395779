/* montserrat-regular - latin */
@font-face {
  font-display: swap;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: local("Montserrat Regular"), local("Montserrat-Regular"),
    url("./assets/fonts/montserrat-v14-latin-regular.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url("./assets/fonts/montserrat-v14-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-500 - latin */
@font-face {
  font-display: swap;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  src: local("Montserrat Medium"), local("Montserrat-Medium"),
    url("./assets/fonts/montserrat-v14-latin-500.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url("./assets/fonts/montserrat-v14-latin-500.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-600 - latin */
@font-face {
  font-display: swap;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  src: local("Montserrat SemiBold"), local("Montserrat-SemiBold"),
    url("./assets/fonts/montserrat-v14-latin-600.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url("./assets/fonts/montserrat-v14-latin-600.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* montserrat-700 - latin */
@font-face {
  font-display: swap;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: local("Montserrat Bold"), local("Montserrat-Bold"),
    url("./assets/fonts/montserrat-v14-latin-700.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url("./assets/fonts/montserrat-v14-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

:root{
  --primaryColor: #00ADD6;
  --darkerPrimary: #007B98;
}

body,
html {
  height: 100%;
  background-color: #f1f1f1;
}

html {
  scroll-behavior: smooth;
  font-size: 62.5%;
}

@media only screen and (max-width: 800px) {
  html {
    font-size: 55%;
  }
}

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  min-height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
