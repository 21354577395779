#footer-container {
  margin-top: 300px;

  color: #fff;
  width: 100%;
  height: 100%;
  font-size: 1.6rem;
  font-weight: 500;
  background-color: #2a2a2a;

  #footer-content {
    position: relative;
    height: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 5vh 2vw 5vh 2vw;
    display: grid;
    grid-template-areas:
      ". . . ."
      "address contact . map"
      "logo social . map"
      "line line line line"
      "copyright . . ."
      ". . . .";
    grid-template-rows: 50px 70px max-content max-content max-content 50px;
    grid-template-columns: max-content auto auto minmax(25%, 50%);
    grid-row-gap: 1em;

    #footer-logo {
      grid-area: logo;
      height: 55px;
      position: absolute;
      bottom: 0;
    }
    #footer-address {
      grid-area: address;
    }
    #footer-contact {
      grid-area: contact;
      margin: 0 0 0 auto;
    }
    #footer-media {
      grid-area: social;
      position: absolute;
      bottom: 0;
      right: 0;
      a {
        width: 50px;
        height: 50px;
        float: right;
        background-color: #525252;
        position: relative;

        img {
          filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(286deg) brightness(103%) contrast(103%);
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      a:nth-child(2) {
        margin-right: 8px;
      }
      a:hover {
        background-color: white;

        img {
          filter: invert(32%) sepia(14%) saturate(0%) hue-rotate(163deg) brightness(91%) contrast(89%);
        }
      }
    }
    #footer-map {
      grid-area: map;
      max-width: 100%;
      height: 300px;
      border: 4px solid #2a2a2a;
      border-radius: 4px;
    }
    .line {
      grid-area: line;
      height: 3px;
      border-radius: 4px;
      background-color: var(--primaryColor);
    }
    #copyright {
      grid-area: copyright;
    }

    p,
    h3 {
      margin: 0;
    }
    p {
      margin-top: 10px;
    }
    a {
      color: white;
      display: block;
      margin-top: 10px;
    }
  }
}

@media only screen and (max-width: 1000px) {
  #footer-container {
    #footer-content {
      margin: 0 auto;
      grid-template-areas:
        "address contact ."
        "map map map"
        "logo social social"
        "line line line"
        "copyright . .";

      grid-template-rows: min-content min-content 50px min-content min-content;
      grid-template-columns: auto;

      #footer-logo {
        width: auto;
        height: 40px;
      }

      #footer-media {
        margin: 0 0 0 auto;
      }

      #footer-map {
        height: 220px;
      }
    }
  }
}
