#home-container {
  min-height: 100vh;
  margin: 0 auto;

  #news {
/*     .news-header {
      background-color: var(--primaryColor);
      margin-bottom: 20px;
      height: 124px;
      position: relative;

      .news-content2 {
        max-width: 1200px;
        margin: 0 auto;

        h1 {
          margin: 0;
          line-height: 124px;
          font-size: 3.6rem;
          font-weight: 500;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          color: white;
        }
        .social-media {
          position: absolute;
          height: 20px;
          padding: 6px;
          top: 50%;
          transform: translateY(-50%);
          right: 20px;
          background-color: white;
          padding: 6px;

          img {
            width: 20px;
            filter: invert(65%) sepia(90%) saturate(3919%) hue-rotate(155deg) brightness(94%) contrast(101%);
          }
        }
        #instagram {
          right: 60px;
        }


      }
    } */
    .news-section{
      display:grid;
      grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
      max-width: 1200px;
      margin: 100px auto;
      column-gap: 2rem;
      row-gap: 8rem;
    }
  }

  #about {
    h1{
      margin:0;
    }
    #about-header {
      padding: 40px;
      font-size: 3.6rem;
      font-weight: 500;
      text-align: center;
      color: white;
      background-color: var(--primaryColor);
    }
  }

  #area-title {
    width: 100%;
    margin: 50px auto 0 auto;
    background-color: var(--primaryColor);
    position: relative;
    top: 1px;

    h1 {
      font-size: 3.6rem;
      font-weight: 500;
      color: white;
      text-align: center;
      margin: 0;
      padding: 50px;
    }
  }

  #areas {
    background-color: white;
  }

  #useCase{
    #useCase-header{
      padding: 40px 0;
      margin: 0;
      font-size: 3.6rem;
      font-weight: 500;
      text-align: center;
      color: white;
      background-color: var(--primaryColor);
    }
    .useCase-section{
      display:grid;
      grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
      max-width: 1200px;
      margin: 100px auto;
      column-gap: 2rem;
      row-gap: 8rem;
    }
  }

  #contact {
    margin-top: 100px;
  }
}

@media only screen and (max-width: 800px) {
  #home-container {
    #areas {
      margin: unset;
    }
  }
}
