.expertises {
  width: 100%;
  justify-content: center;
  min-height: max-content;
  max-height: auto;
  background-color: #2a2a2a;
  color: #e1e1e1;

  display: grid;
  grid-template-areas:
    "l .     r"
    "l title r"
    "l desc  r"
    "l .     r";
  grid-template-columns: minmax(115px, auto) minmax(auto, 745px) minmax(115px, auto);
  grid-template-rows: 30px max-content auto 30px;

  .hiddenTest {
    position: relative;
    top: -60px;
  }

  #expertise-banner {
    min-height: 400px;
    max-height: 100%;
    grid-area: r;
    background-color: var(--primaryColor);

    display: grid;
    grid-template-rows: auto max-content auto;

    .icon {
      margin: 0 auto;
      img {
        width: 90px;
      }
    }

    #line {
      background: linear-gradient(#fff, #fff) no-repeat top center/2px 97%;
    }
    #line2 {
      background: linear-gradient(#fff, #fff) no-repeat center/2px 100%;
    }
  }

  #ribbon-text{
    grid-area: l;
    width: 300px;
    background-color: var(--primaryColor);

    margin: auto;
    padding: 20px;
    text-align: center;
    border-left: 1px solid var(--primaryColor);
    border-right: 1px solid var(--primaryColor);

    h1, p {
      width: 100%;
      margin: 16px auto 8px auto;
    }

    a{
      color: white;
    }

    img{
      width: 100%;
    }
  }

  .title {
    grid-area: title;
    font-size: 2.5rem;
    font-weight: 500;
    line-height: 42px;
    margin: 0 50px 0 50px;
    max-width: 660px;
  }

  .desc {
    grid-area: desc;
    font-size: 1.5rem;
    line-height: 36px;
    max-width: 700px;
    margin: 0 50px 0 50px;
  }
}

@media only screen and (max-width: 1000px) {
  .expertises {
    grid-template-areas:
      "."
      "title"
      "desc"
      ".";
    grid-template-rows: 4em max-content auto 4em;
    grid-template-columns: 100%;

    #expertise-banner {
      display: none;
    }
    #ribbon-text{
      display:none;
    }

    .title {
      margin: 0 25px 0 25px;
    }
  
    .desc {
      margin: 0 25px 0 25px;
    }
  }

}
