.news2-container {
  margin: 0 auto;
  width: 100%;
  height: min-content;
  max-width: 475px;
  background-color: white;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);

  display: grid;
  grid-template-areas:
    "img"
    "headline"
    "description"
    "line"
    "readmore";
  grid-template-rows: min-content max-content max-content 1px 40px;

  .news2-img {
    grid-area: img;
    height: 215px;
    padding: 20px;
    background-color: #c4c4c4;
    .tag {
      padding: 6px;
      display: inline-block;
      min-width: 70px;
      background-color: var(--darkerPrimary);
      border-radius: 16px;
      color: white;
      font-weight: 600;
      font-size: 1.2rem;
      text-align: center;
      text-transform: uppercase;
    }
  }
  .news2-text {
    padding: 25px;
    line-height: 22px;
    h1 {
      font-size: 2.2rem;
    }
    p {
      font-size: 1.35rem;
    }
  }

  .line {
    grid-area: line;
    width: 100%;
    border-radius: 16px;
    background-color: #e5e5e5;
  }
  .readMore {
    grid-area: readmore;
    margin-top: 10px;
    height: 24px;
    text-align: center;
    font-size: 2rem;
    color: #d0d0d0;
    font-weight: 500;
    text-decoration: none;
  }
  .hiddenTest {
    position: relative;
    top: -780px;
  }
}
