.news-container {
  padding: 20px;
  .news-content {
    max-width: 800px;
    max-height: auto;
    background-color: white;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 40px;

    display: grid;
    grid-template-areas:
      "h h v"
      "p p v"
      "av1 av2 v"
      "i i i";
    grid-template-columns: 10% 65% auto;
    grid-template-rows: max-content auto;

    margin: 0 auto;
    margin-bottom: 40px;

    .headline {
      grid-area: h;
      font-size: 2.6rem;
      font-weight: 600;
      margin: 0;
    }
    .desc {
      grid-area: p;
      margin: 0;
      font-size: 1.4rem;
      line-height: 28px;
    }
    a {
      width: 160px;
      padding: 15px;
      font-size: 1.6rem;
      font-weight: bold;
      text-decoration: none;
      text-align: center;
      color: white;
      background-color: var(--primaryColor);
    }
    #avatars {
      border-radius: 50%;
      width: 60px;

      &:nth-child(0) {
        grid-area: av1;
      }
      &:nth-child(1) {
        grid-area: av2;
      }
    }

    video {
      grid-area: v;
      margin: 0 auto;
      width: 130px;
    }
  }
}

@media only screen and (max-width: 800px) {
  .news-container {
    padding: unset;
    .news-content {
      grid-template-areas:
        "h av1 av2"
        "p p p"
        "v v v";
      grid-template-columns: auto min-content min-content;
      grid-template-rows: max-content auto;
      padding: 20px;

      .headline {
        line-height: 60px;
      }
    }
  }
}
